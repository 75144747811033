<template>
  <v-dialog v-model="datenweitergabe" persistent :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'">
    <v-toolbar    :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'" ><h2>Datenweitergabe</h2></v-toolbar>
    <v-card class="pt-4">
      <v-card-text>
        Ich erklärte mich mit der Weitergabe meiner Unterlagen an die STAG Legal Rechtsanwaltsgesellschaft und ihres technischen Dienstleisters STAG Dienstleistungsgesellschaft einverstanden und entbinde sie insoweit von ihrer Schweigepflicht.
        <v-row>
          <v-col cols="auto">
            <v-card class="mt-3" :color="$route.query.insurance==='MPU' ? 'blue' : 'primary'" >
              <v-card-title>Widerruf</v-card-title>
              <v-card-text>
                STAG Legal Rechtsanwaltsgesellschaft UG (haftungsbeschränkt)<br /> Sackstraße 2<br /> 37115 Duderstadt<br /> E-Mail: <a style="color:white" href="mailto:info@stag-legal.de">info@stag-legal.de</a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="mr-3"
            to="/privacy"
        >
          Datenschutz
        </v-btn>
        <v-btn    :color="$route.query.insurance==='MPU' ? 'blue' : 'secondary'" @click="datenweitergabe = false">weiter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DatenweitergabeDialog",
  data: () => ({
    datenweitergabe: true,
  })
}
</script>

<style scoped>

</style>
