<template>
  <v-card color="error">
    <v-img :src="require('@/assets/R2D2Error.gif')" class="align-end">
      <v-card-subtitle class="float-right" style="font-size: 12px">source: https://www.pinterest.es/pin/267401296600359647/</v-card-subtitle>
    </v-img>
    <v-card-title>
      Der Link ist nicht vollständig.
    </v-card-title>
    <v-card-text class="white--text">
      Stellen Sie sicher, dass Sie den Link korrekt aus der E-Mail übernommen haben.
      <br />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn href="mailto:info@dokumentenanwalt.de?subject=Link%20ohne%20Parameter" >Kontakt aufnehmen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ErrorCard"
}
</script>

<style scoped>

</style>
