import {
    getApiURL,
} from "@/configBuilder";
import Vue from "vue"

/* START LEGAL REQUESTS */
export async function uploadLegalRequest(key, formData, insurance) {
  return new Promise((resolve, reject) => {
      Vue.axios.post(
      getApiURL(insurance + "Data/" + key + "/Document"),
      formData
    )
      .then((response) => {
        return resolve(response)
      }).catch(error => {
      return reject(error)
    })
  })
}

/* END LEGAL REQUESTS */

/* HELPER START */
Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
Date.prototype.removeDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
}
/* HELPER END */
